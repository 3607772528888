import './Tab.css'
import React from 'react';


export default class Tab extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hidden: true, displayHidden: true};
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    render() {
        let image = this.props.tab.hasOwnProperty("image") ? { backgroundImage: "url(" + this.props.tab.image + ")" } : {};
        let opacity = {opacity: this.state.hidden ? 0 : 1};
        let title = this.props.tab.selected ? "" : <div className="grid-item-text">{this.props.tab.name}</div>;

        if(this.state.hidden && !this.props.tab.hidden){
            this.show();
        }else if(!this.state.hidden && this.props.tab.hidden){
            this.hide();
        }

        if(this.state.displayHidden){
            return ""
        }

        return (
            <div className={"grid-item" + (this.props.tab.selected ? " selected" : "")} style={Object.assign(image, opacity)} onClick={this.onClick}>
                {title}
            </div>
        )
    }

    onClick(){
        this.props.onClick(this.props.id);
    }

    show(){
        setTimeout(
            function () {
                this.setState({ displayHidden: false });
            }.bind(this), 1);

        setTimeout(
            function () {
                this.setState({ hidden: false });
            }.bind(this), this.props.id * this.props.id * 10);
    }

    hide(){
        setTimeout(
            function () {
                this.setState({ displayHidden: true });
            }.bind(this), 1000);

        setTimeout(
            function () {
                this.setState({ hidden: true });
            }.bind(this), this.props.id * this.props.id * 10);
    }
}