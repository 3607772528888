import './App.css';
import React from 'react';
import logo from './images/logo.png';
import Image from 'react-bootstrap/Image';
import Footer from 'react-bootstrap/ModalFooter';
import CircularIconContainer from './components/CircularIconContainer'
import TabsContainer from './components/TabsContainer'

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hidden: false };
  }

  render() {
    let logoElement = this.state.hidden ? "" : <Image src={logo} className="main-logo" alt="logo" roundedCircle />;

    return (
      <div className="App">
        <div className="App-body">

          <div className="main-panel">
            {/* TODO: potentially remove bootstrap */}
            {logoElement}
            <div className={this.state.hidden ? "main-panel-background main-panel-extended" : "main-panel-background"}>
              <TabsContainer
                selected={this.state.selected}
                hidden={!this.state.hidden}
                onTabSelect={() => this.onTabSelect()}
                onTabBack={() => this.onTabBack()}
              ></TabsContainer>
              <CircularIconContainer onSelect={(id) => this.onSelect(id)} onBack={() => this.onBack()} tabSelected={this.state.tabSelected}></CircularIconContainer>
            </div>
          </div>


        </div>

        <Footer className="App-footer">
          <div>
            Copyright &copy; Mahan Pandey {new Date().getFullYear()}
          </div>
        </Footer>
      </div>
    );
  }

  onSelect(icon) {
    this.setState({ hidden: true, selected: icon });
  }

  onBack() {
    this.setState({ hidden: false, selected: undefined });
  }

  onTabSelect(icon) {
    this.setState({ tabSelected: true });
  }

  onTabBack() {
    this.setState({ tabSelected: false });
  }

}