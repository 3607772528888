const initialState = [];

const icons = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ICON':
      return [
        ...state,
        {
          id: action.id,
          name: action.name, 
          image: action.image,
          degree: action.degree,
          destination: {hidden: action.hidden, degree: action.degree}
        }
      ]
      
      case 'SELECT_ICON':
        return state.map(icon => (icon.id === action.id) ? {...icon,  destination: {hidden: false, degree: 0}} : {...icon,  destination: {hidden: true, degree: 0}})
      
      case 'RESET_ICONS':
        return state.map(icon => { return {...icon,  destination: {hidden: false, degree: icon.degree}}});
      
        default:
      return state
  }
}

export default icons
