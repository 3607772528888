import './Icon.css'
import React from 'react';

export default class Icon extends React.Component {
    constructor(props) {
        super(props);
        this.state = { current: { degree: 0, hidden: true }, animating: false };
        this.onClick = this.onClick.bind(this);
        this.rotateFromStartToPosition = this.rotateFromStartToPosition.bind(this);
    }

    render() {
        const deg = this.state.current.degree,
            hidden = this.state.current.hidden;

        if (!this.state.animating) {
            if (JSON.stringify(this.state.current) !== JSON.stringify(this.props.destination)) {
                if (!this.props.destination.hidden) {
                    this.rotateFromStartToPosition();
                }
                else {
                    this.rotateFromPositionToStart();
                }
            }
        }

        let icon = hidden ? "" : <div className="parent" style={{ transform: 'rotate(' + deg + 'deg)' }}>
            <div className="child" style={{ transform: 'rotate(' + -deg + 'deg)' }}>
                <img src={this.props.image} className="child-img" alt={this.props.image.name} onClick={this.onClick} />
                {this.props.text}
            </div>
        </div>;

        return (
          icon
        );
    }

    rotateFromStartToPosition() {
        setTimeout(
            function () {
                this.setState({ animating: true, current: { hidden: this.props.destination.hidden } });
                setTimeout(
                    function () {
                        this.setState({ animating: false, current: { ...this.state.current, degree: this.props.destination.degree } });
                        //For tabs container debugging
                        // this.onClick();
                    }.bind(this), 360 - this.props.destination.degree);
            }.bind(this), (360 - this.props.destination.degree) * 3);
    }

    rotateFromPositionToStart() {
        setTimeout(
            function () {
                this.setState({ animating: true, current: { ...this.state.current, degree: this.props.destination.degree } });
            }.bind(this), 1);
        setTimeout(
            function () {
                this.setState({ animating: false, current: { ...this.state.current, hidden: this.props.destination.hidden } });
            }.bind(this), this.state.current.degree * 2 + 100);
    }

    onClick() {
        this.setState({ animating: true });
        this.props.onClick(this.state.current.degree);
        setTimeout(
            function () {
                this.rotateFromPositionToStart();
            }.bind(this), this.state.current.degree * 2 + 100);
    }
}

