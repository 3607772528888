import './CircularIconContainer.css'
import React from 'react';
import { connect } from "react-redux";
import { addTodo, selectIcon, resetIcons } from "../actions/actions";

import Icon from './Icon';
import Work from "../images/work.png"
import Sports from "../images/sports.png"
import Career from "../images/career.png"
import Hobby from "../images/hobby.png"
import Media from "../images/media.png"
import Academics from "../images/academic.png"

class CircularIconContainer extends React.Component {

        constructor(props) {
            super(props);
            const icons = [300, 240, 180, 120, 60, 0];
            const images = [Media, Academics, Work, Hobby, Sports, Career];
            const names = ["Media", "Academics", "Projects", "Hobbies", "Sports", "Career"];

            for (let i = 0; i < icons.length; i++) {
                this.props.addTodo(icons[i], names[i], images[i]);
            }
            this.click = this.click.bind(this);
            this.reset = this.reset.bind(this);
            this.state = { selected: false };

            // AUTO select for debugging purposes
            // setTimeout(
            //     function () {
            //         this.click(5);
            //     }.bind(this), 1);

        }

        render() {
                let className = this.state.selected ? "icons-selected" : "icons";
                let button = this.state.selected && !this.props.tabSelected ?
                    <
                    button onClick = { this.reset }
                className = "button" > Home! < div className = "button-extension" > < /div></button >: "";

                return ( <
                        >
                        { button } <
                        div className = { className } > {
                            this.props.icons.map((icon) => ( < Icon key = { icon.id }
                                    text = { icon.name }
                                    destination = { icon.destination }
                                    image = { icon.image }
                                    onClick = {
                                        () => this.click(icon.id)
                                    } > < /Icon>))} < /
                                    div > <
                                    />
                                );
                            }

                            click(id) {
                                this.props.selectIcon(id);
                                this.props.onSelect(this.props.icons[id]);
                                this.setState({ selected: true });
                            }

                            reset() {
                                this.setState({ selected: false });
                                this.props.resetIcons();
                                this.props.onBack();
                            }

                        }

                        function mapStateToProps(state) {
                            const icons = state.icons;
                            return { icons };
                        }


                        export default connect(mapStateToProps, { addTodo, selectIcon, resetIcons })(CircularIconContainer);