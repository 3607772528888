import tabList from '../tabs.json'

const initialState = [];

const tabs = (state = initialState, action) => {
  switch (action.type) {
    case 'START_TABS':
      return tabList.filter(tab => tab.tags.includes(action.category)).map(function (tab) { return { ...tab, hidden: false, selected: false } })
    case 'END_TABS':
      return state.map(function (tab) { return { ...tab, hidden: true } })
    case 'SELECT_TAB':
      return state.map(function (tab, id) {
        if(id !== action.id)
          return { ...tab, hidden: true }
        else
          return {...tab, selected: true }
      })
    default:
      return state
  }
}

export default tabs
