let nextIconId = 0
export const addTodo = (degree, name, image) => ({
  type: 'ADD_ICON',
  id: nextIconId++,
  degree: degree,
  name: name, 
  image: image,
  hidden: false
})

// export const setVisibilityFilter = filter => ({
//   type: 'SET_VISIBILITY_FILTER',
//   filter
// })

export const selectIcon = id => ({
  type: 'SELECT_ICON',
  id
})

export const resetIcons = () => ({
  type: 'RESET_ICONS'
})

// export const VisibilityFilters = {
//   SHOW_ALL: 'SHOW_ALL',
//   SHOW_COMPLETED: 'SHOW_COMPLETED',
//   SHOW_ACTIVE: 'SHOW_ACTIVE'
// }

export const startTabs = category => ({
  type : "START_TABS",
  category
})

export const selectTab = id => ({
  type : "SELECT_TAB",
  id
})

export const endTabs = () => ({
  type : "END_TABS",
})