import './TabsContainer.css'
import { startTabs, selectTab, endTabs } from '../actions/actions'
import { connect } from "react-redux";
import React from 'react';
import Tab from './Tab'

class TabsContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hidden: true, selected: false };
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.reset = this.reset.bind(this);
        this.onClick = this.onClick.bind(this);

        // AUTO select for debugging purposes
        // setTimeout(
        //     function () {
        //         this.onClick(5);
        //     }.bind(this), 500);
    }

    render() {
        if (this.props.selected === undefined && this.state.selected) {
            setTimeout(
                function () {
                    this.setState({ selected: false });
                }.bind(this), 1);
        }
        if (!this.props.hidden && this.state.hidden) {
            this.show();
        } else if (this.props.hidden && !this.state.hidden) {
            this.hide();
        }
        let background = this.props.hidden ? "" : <div className="grid-background"></div>;
        let tabDetails = this.state.selected ?
            <>
                <button onClick={this.reset} className="grid-button">Back!<div className="button-extension"></div></button>
                <div className="detail-container">
                    <base target="_blank"></base>
                    <div className="detail-title">{this.state.selectedTab.name}</div>
                    <div className="detail-description">{this.state.selectedTab.description}</div>
                    <div className="detail-text" dangerouslySetInnerHTML={{ __html: this.state.detailText }} />
                </div>
            </> : "";

        return (
            <div className="container">
                {background}
                <div className="grid-container">
                    {this.props.tabs.map((tab, id) =>
                        <Tab key={tab.name} tab={tab} id={id} onClick={() => this.onClick(id)} />
                    )}
                </div>
                {tabDetails}
            </div>
        )
    }

    onClick(id) {
        this.props.selectTab(id);
        this.props.onTabSelect();
        if (this.props.tabs[id].text !== undefined) {
            fetch('/tabs/' + this.props.tabs[id].text)
                .then((r) => r.text())
                .then(text => {
                    this.setState({ detailText: text });
                })
        } else {
            fetch('/tabs/notext.html')
                .then((r) => r.text())
                .then(text => {
                    this.setState({ detailText: text });
                })
        }

        this.setState({ selectedTab: this.props.tabs[id] });

        setTimeout(
            function () {
                this.setState({ selected: true });
            }.bind(this), 1000 + 10 * id);
    }

    show() {
        setTimeout(
            function () {
                this.props.startTabs(this.props.selected.name);
                this.setState({ hidden: false });
            }.bind(this), 1);
    }

    hide() {
        setTimeout(
            function () {
                this.props.endTabs();
                this.setState({ hidden: true });
            }.bind(this), 1);
    }

    reset() {
        this.props.onTabBack();
        this.props.startTabs(this.props.selected.name);
        this.setState({ hidden: false, selected: false });
    }
}

function mapStateToProps(state) {
    const tabs = state.tabs;
    return { tabs };
}


export default connect(mapStateToProps, { startTabs, selectTab, endTabs })(TabsContainer);
